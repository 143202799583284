<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="userData === undefined">
      <h4 class="alert-heading">Error al buscar usuario</h4>
      <div class="alert-body">
        Usuario no encontrado
        <b-link class="alert-link" :to="{ name: 'apps-users-list' }">
          Revisa la lista de usuarios
        </b-link>
      </div>
    </b-alert>

    <b-card-title> Editar usuario </b-card-title>

    <user-edit-tab-account
      v-if="userData !== undefined"
      :user-data="userData"
    />
  </component>
</template>

<script>
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import userStoreModule from "../userStoreModule";
import UserEditTabAccount from "./UserEditTabAccount.vue";
import UserEditTabInformation from "./UserEditTabInformation.vue";
import UserEditTabSocial from "./UserEditTabSocial.vue";

export default {
  components: {
    UserEditTabAccount,
    UserEditTabInformation,
    UserEditTabSocial,
  },
  setup() {
    const userData = ref(null);

    const USER_APP_STORE_MODULE_NAME = "app-user";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    store
      .dispatch("app-user/fetchUser", { id: router.currentRoute.params.id })
      .then((response) => {
        userData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          userData.value = undefined;
        }
      });

    return {
      userData,
    };
  },
};
</script>

<style></style>
